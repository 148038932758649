import React, { useState } from 'react';
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";

const Overs = ({ fixture }) => {
  const [showPercentages, setShowPercentages] = useState(true);

  const togglePercentages = () => {
    setShowPercentages(!showPercentages);
  };

  if (!fixture.recent) return null;

  return (
    <Table bordered className="main" onClick={togglePercentages}>
      <tbody>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Overs</td>
          <td className="Cells" style={styles.Cells}> </td>
          <td className="Cells" style={styles.Cells}> </td>
        </tr>
        <tr>
          <td className="Cells" style={styles.Cells}> </td>
          <td className="Cells" style={styles.Cells}>{fixture.home_team}</td>
          <td className="Cells" style={styles.Cells}>{fixture.away_team}</td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Last 3</td>
          <td className="Cells" style={styles.Cells}>
            {showPercentages
              ? `${((fixture.recent.home_overs_hit_3 / 3) * 100).toFixed(1)}%`
              : `${fixture.recent.home_overs_hit_3} - ${3 - fixture.recent.home_overs_hit_3}`}
          </td>
          <td className="Cells" style={styles.Cells}>
            {showPercentages
              ? `${((fixture.recent.away_overs_hit_3 / 3) * 100).toFixed(1)}%`
              : `${fixture.recent.away_overs_hit_3} - ${3 - fixture.recent.away_overs_hit_3}`}
          </td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Last 5</td>
          <td className="Cells" style={styles.Cells}>
            {showPercentages
              ? `${((fixture.recent.home_overs_hit_5 / 5) * 100).toFixed(1)}%`
              : `${fixture.recent.home_overs_hit_5} - ${5 - fixture.recent.home_overs_hit_5}`}
          </td>
          <td className="Cells" style={styles.Cells}>
            {showPercentages
              ? `${((fixture.recent.away_overs_hit_5 / 5) * 100).toFixed(1)}%`
              : `${fixture.recent.away_overs_hit_5} - ${5 - fixture.recent.away_overs_hit_5}`}
          </td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Last 10</td>
          <td className="Cells" style={styles.Cells}>
            {showPercentages
              ? `${((fixture.recent.home_overs_hit_10 / 10) * 100).toFixed(1)}%`
              : `${fixture.recent.home_overs_hit_10} - ${10 - fixture.recent.home_overs_hit_10}`}
          </td>
          <td className="Cells" style={styles.Cells}>
            {showPercentages
              ? `${((fixture.recent.away_overs_hit_10 / 10) * 100).toFixed(1)}%`
              : `${fixture.recent.away_overs_hit_10} - ${10 - fixture.recent.away_overs_hit_10}`}
          </td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Season</td>
          <td className="Cells" style={styles.Cells}>
            {showPercentages
              ? `${((fixture.stats.home_overs_hit / fixture.stats.count_home) * 100).toFixed(1)}%`
              : `${fixture.stats.home_overs_hit} - ${fixture.stats.count_home - fixture.stats.home_overs_hit}`}
          </td>
          <td className="Cells" style={styles.Cells}>
            {showPercentages
              ? `${((fixture.stats.away_overs_hit / fixture.stats.count_away) * 100).toFixed(1)}%`
              : `${fixture.stats.away_overs_hit} - ${fixture.stats.count_away - fixture.stats.away_overs_hit}`}
          </td>
        </tr>
        {shouldShowLastSeason(fixture) && (
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>Last Season</td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.stats.home_overs_hit_last / fixture.stats.count_home_last) * 100).toFixed(1)}%`
                : `${fixture.stats.home_overs_hit_last} - ${fixture.stats.count_home_last - fixture.stats.home_overs_hit_last}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.stats.away_overs_hit_last / fixture.stats.count_away_last) * 100).toFixed(1)}%`
                : `${fixture.stats.away_overs_hit_last} - ${fixture.stats.count_away_last - fixture.stats.away_overs_hit_last}`}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

// This function should be moved to a utility file
function shouldShowLastSeason(fixture) {
  if (fixture.league === "NFL") {
    return true;
  }
  return fixture.ratings.progress < 0.15;
}

export default Overs;