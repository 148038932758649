import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useAppState } from '../AppStateContext';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  Alert
} from 'reactstrap';

const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAppState();

  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${url}/auth/login/`, {
        username,
        password
      });
      login(response.data.token);
      navigate('/');
    } catch (error) {
      setError('Request failed: ' + JSON.stringify(error.response?.data));
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="mt-5">
            <CardHeader>Sign In</CardHeader>
            <CardBody>
              {error && <Alert color="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="bg-dark text-white mb-3 py-2"
                    style={{ fontSize: '16px', height: 'auto' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="bg-dark text-white mb-3 py-2"
                    style={{ fontSize: '16px', height: 'auto' }}
                  />
                </FormGroup>
                <Button type="submit" color="primary" block>Sign In</Button>
              </Form>
              <div className="mt-3 text-center">
                <Link to="/passwordresetrequest">Forgot Password?</Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
