import React from 'react';

const Versus = ({ fixture }) => {
  if (
    fixture.ratings &&
    fixture.ratings.home_power_rating &&
    fixture.ratings.away_power_rating &&
    fixture.ratings.home_pre_elo &&
    fixture.ratings.away_pre_elo
  ) {
    return (
      <span>
        {fixture.home_team +
          "(" +
          fixture.ratings.home_pre_elo.toFixed(0) +
          ")" +
          "(" +
          fixture.ratings.home_point_diff_rating.toFixed(0) +
          ") "}{' '}
        vs{' '}
        {fixture.away_team +
          "(" +
          fixture.ratings.away_pre_elo.toFixed(0) +
          ")" +
          "(" +
          fixture.ratings.away_point_diff_rating.toFixed(0) +
          ") "}
      </span>
    );
  }
  if (
    fixture.ratings &&
    fixture.ratings.home_power_rating &&
    fixture.ratings.away_power_rating
  ) {
    return (
      <span>
        {fixture.home_team +
          "(" +
          fixture.ratings.home_power_rating.toFixed(0) +
          ") "}{' '}
        vs{' '}
        {fixture.away_team +
          "(" +
          fixture.ratings.away_power_rating.toFixed(0) +
          ") "}
      </span>
    );
  }
  return (
    <span>
      {fixture.home_team} vs {fixture.away_team}
    </span>
  );
};

export default Versus;