import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";
import { StarRating } from "../../components/starrating.js";
import { useAppState } from "../../AppStateContext.js";

function oddsToProb(odds) {
  let impliedProbability;
  if (odds > 0) {
    impliedProbability = 100 / (odds + 100);
  } else {
    impliedProbability = Math.abs(odds) / (Math.abs(odds) + 100);
  }
  return (impliedProbability * 100).toFixed(2);
}

function getHomeSpreadProb(fixture) {
  if (fixture.home_spread) {
    return oddsToProb(fixture.home_spread_price);
  }
}

function getAwaySpreadProb(fixture) {
  if (fixture.away_spread) {
    return oddsToProb(fixture.away_spread_price);
  }
}

function getHomeMoneylineProb(fixture) {
  if (fixture.home_moneyline) {
    return oddsToProb(fixture.home_moneyline);
  }
  return 0;
}

function getAwayMoneylineProb(fixture) {
  if (fixture.away_moneyline) {
    return oddsToProb(fixture.away_moneyline);
  }
  return 0;
}

function calculateVig(prob1, prob2) {
  return ((parseFloat(prob1) + parseFloat(prob2) - 100)/2).toFixed(2);
}

function calculateStars(ev1, ev2) {
  if (isNaN(ev1) || isNaN(ev2)) return 0;
  if (ev1 < 0 && ev2 < 0) {
    return Math.abs(ev1) < Math.abs(ev2) ? 1 : 0;
  } else if (ev1 > 0 && ev1 <= 3) {
    return 2;
  } else if (ev1 > 3) {
    return 3;
  }
  return 0;
}

function Projections({ fixture }) {
  const [displayMode, setDisplayMode] = useState(2); // 2: Future State, 0: Probabilities, 1: EV
  const { isTutorialMode } = useAppState();
  const toggleDisplayMode = () => {
    setDisplayMode((prevMode) => (prevMode + 1) % 3);
  };

  if (fixture.ratings && fixture.insights) {
    const modelTypes = [
      "MLPClassifier",
      "LogisticRegression",
      "HistGradientBoostingClassifier",
    ];

    const modelAliases = {
      "MLPClassifier": "Model A",
      "LogisticRegression": "Model B",
      "HistGradientBoostingClassifier": "Model C"
    };

    // Pre-calculate all values
    const homeEloWinProb = (fixture.ratings.home_elo_prob_ml * 100).toFixed(1);
    const awayEloWinProb = (fixture.ratings.away_elo_prob_ml * 100).toFixed(1);
    const homeMoneylineProb = getHomeMoneylineProb(fixture);
    const awayMoneylineProb = getAwayMoneylineProb(fixture);
    const homeEloSpreadProb = (
      fixture.ratings.home_elo_prob_spread * 100
    ).toFixed(1);
    const awayEloSpreadProb = (
      fixture.ratings.away_elo_prob_spread * 100
    ).toFixed(1);
    const homeSpreadProb = getHomeSpreadProb(fixture);
    const awaySpreadProb = getAwaySpreadProb(fixture);

    // New constants for vig calculation
    const moneylineVig = calculateVig(homeMoneylineProb, awayMoneylineProb);
    const spreadVig = calculateVig(homeSpreadProb, awaySpreadProb);

    // Calculate totals vig
    const overProb = oddsToProb(fixture.over_price);
    const underProb = oddsToProb(fixture.under_price);
    const totalsVig = calculateVig(overProb, underProb);

    const modelProbabilities = modelTypes.map((model) => {
      const homeWinProb = (
        fixture.insights[`home_win_prob_${model}`] * 100
      ).toFixed(1);
      const awayWinProb = (
        fixture.insights[`away_win_prob_${model}`] * 100
      ).toFixed(1);
      const homeCoverProb = (
        fixture.insights[`home_cover_prob_${model}`] * 100
      ).toFixed(1);
      const awayCoverProb = (
        fixture.insights[`away_cover_prob_${model}`] * 100
      ).toFixed(1);
      const overProb = (fixture.insights[`over_prob_${model}`] * 100).toFixed(
        1
      );
      const underProb = (fixture.insights[`under_prob_${model}`] * 100).toFixed(
        1
      );

      return {
        model: modelAliases[model] || model.toUpperCase(),
        homeWinProb,
        awayWinProb,
        homeCoverProb,
        awayCoverProb,
        overProb,
        underProb,
        homeWinEV: (
          parseFloat(homeWinProb) - parseFloat(homeMoneylineProb)
        ).toFixed(2),
        awayWinEV: (
          parseFloat(awayWinProb) - parseFloat(awayMoneylineProb)
        ).toFixed(2),
        homeCoverEV: (
          parseFloat(homeCoverProb) - parseFloat(homeSpreadProb)
        ).toFixed(2),
        awayCoverEV: (
          parseFloat(awayCoverProb) - parseFloat(awaySpreadProb)
        ).toFixed(2),
        overEV: (
          parseFloat(overProb) - parseFloat(oddsToProb(fixture.over_price))
        ).toFixed(2),
        underEV: (
          parseFloat(underProb) - parseFloat(oddsToProb(fixture.under_price))
        ).toFixed(2),
      };
    });

    const homeEloEV = (
      parseFloat(homeEloWinProb) - parseFloat(homeMoneylineProb)
    ).toFixed(2);
    const awayEloEV = (
      parseFloat(awayEloWinProb) - parseFloat(awayMoneylineProb)
    ).toFixed(2);

    const homeEloRating = fixture.ratings.home_pre_elo.toFixed(0);
    const awayEloRating = fixture.ratings.away_pre_elo.toFixed(0);
    const homePointDiffRating =
      fixture.ratings.home_point_diff_rating.toFixed(0);
    const awayPointDiffRating =
      fixture.ratings.away_point_diff_rating.toFixed(0);

    return (
      <>
  <>
    {isTutorialMode && (
      <ul style={{ marginBottom: '20px', textAlign: 'left', listStyleType: 'none', paddingLeft: '0' }}>
        <li>• Tap the table to toggle between modes</li>
          <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
            <li>◦ Probabilities mode - show the probability predicted by the model</li>
            <li>◦ EV (Expected Value) - the difference between the predicted probability and the market's probability</li>
            <li>◦ Star mode - for fast evaluation of of EV</li>
          </ul>
        <li>• Star ratings
          <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
            <li>◦ ⭐ – Slight or no edge, we agree with the market pricing</li>
            <li>◦ ⭐⭐ – Moderate value bet, we think it's a good bet for the price that the market offers</li>
            <li>◦ ⭐⭐⭐ – High value bet, we think it has a much higher probability of success than the market has priced it</li>
            <li>◦ Keep in mind that these reflect the EV. It is recommended to change to Probabilities mode to see the actual probabilities and get a better understanding of the risks involved.</li>
          </ul>
        </li>
        <li>• How to interpret the data</li>
        <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
            <li>◦ Pay attention to whether the models agree with each other</li>
            <li>◦ Use the vig to gauge the market's confidence in their model's predictions</li>
        </ul>
        <li>• Elo Ratings</li>
          <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
            <li>◦ Elo Ratings are tuned using Bayesian optimization to provide the most accurate ratings possible</li>
          </ul>
      </ul>
    )}
  </>
      <Table bordered className="main" onClick={toggleDisplayMode}>
        <tbody>
          <tr style={styles.Cells}>
            <td colSpan="4" className="Cells" style={styles.Cells}>
              Projections (
              {displayMode === 2
                ? "Stars"
                : displayMode === 0
                ? "Probabilities"
                : "EV"}
              )
            </td>
          </tr>
          <tr>
            <td className="Cells" style={styles.Cells}></td>
            <td className="Cells" style={styles.Cells}>
              Model
            </td>
            <td className="Cells" style={styles.Cells}>
              {fixture.home_team}
            </td>
            <td className="Cells" style={styles.Cells}>
              {fixture.away_team}
            </td>
          </tr>

          {/* Moneyline Group */}
          <tr style={styles.Cells}>
            <td
              colSpan="4"
              className="Cells"
              style={{ ...styles.Cells, borderTop: "2px solid black" }}
            >
              Moneyline
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Elo Win Probability
            </td>
            <td className="Cells" style={styles.Cells}>
              Elo
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 2
                ? <StarRating rating={calculateStars(parseFloat(homeEloEV), parseFloat(awayEloEV))} />
                : displayMode === 0
                ? `${homeEloWinProb}%`
                : `${homeEloEV}%`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 2
                ? <StarRating rating={calculateStars(parseFloat(awayEloEV), parseFloat(homeEloEV))} />
                : displayMode === 0
                ? `${awayEloWinProb}%`
                : `${awayEloEV}%`}
            </td>
          </tr>
          {modelProbabilities.map(
            ({ model, homeWinProb, awayWinProb, homeWinEV, awayWinEV }) => (
              <tr key={`win_${model}`} style={styles.Cells}>
                <td className="Cells" style={styles.Cells}>
                </td>
                <td className="Cells" style={styles.Cells}>
                  {modelAliases[model] || model}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 2
                    ? <StarRating rating={calculateStars(parseFloat(homeWinEV), parseFloat(awayWinEV))} />
                    : displayMode === 0
                    ? `${homeWinProb}%`
                    : `${homeWinEV}%`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 2
                    ? <StarRating rating={calculateStars(parseFloat(awayWinEV), parseFloat(homeWinEV))} />
                    : displayMode === 0
                    ? `${awayWinProb}%`
                    : `${awayWinEV}%`}
                </td>
              </tr>
            )
          )}

          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Market Moneyline Probability + vig
            </td>
            <td className="Cells" style={styles.Cells}>
              Market
            </td>
            <td className="Cells" style={styles.Cells}>
              {homeMoneylineProb}%
            </td>
            <td className="Cells" style={styles.Cells}>
              {awayMoneylineProb}%
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Moneyline Vig
            </td>
            <td className="Cells" style={styles.Cells}>
              Market
            </td>
            <td colSpan="2" className="Cells" style={styles.Cells}>
              {moneylineVig}%
            </td>
          </tr>
          
          <tr>
            <td colSpan="4" style={{ padding: 0 }}>
              <div style={{ borderTop: '0.1px solid white', margin: '0.1px 0' }}></div>
            </td>
          </tr>

          {/* Spread Group */}
          <tr style={styles.Cells}>
            <td
              colSpan="4"
              className="Cells"
              style={{ ...styles.Cells, borderTop: "2px solid black" }}
            >
              Spread
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Elo Spread Probability
            </td>
            <td className="Cells" style={styles.Cells}>
              Elo
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 2
                ? <StarRating rating={calculateStars(
                    parseFloat(homeEloSpreadProb) - parseFloat(homeSpreadProb),
                    parseFloat(awayEloSpreadProb) - parseFloat(awaySpreadProb)
                  )} />
                : displayMode === 0
                ? `${homeEloSpreadProb}%`
                : `${(parseFloat(homeEloSpreadProb) - parseFloat(homeSpreadProb)).toFixed(2)}%`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 2
                ? <StarRating rating={calculateStars(
                    parseFloat(awayEloSpreadProb) - parseFloat(awaySpreadProb),
                    parseFloat(homeEloSpreadProb) - parseFloat(homeSpreadProb)
                  )} />
                : displayMode === 0
                ? `${awayEloSpreadProb}%`
                : `${(parseFloat(awayEloSpreadProb) - parseFloat(awaySpreadProb)).toFixed(2)}%`}
            </td>
          </tr>
          {modelProbabilities.map(
            ({
              model,
              homeCoverProb,
              awayCoverProb,
              homeCoverEV,
              awayCoverEV,
            }) => (
              <tr key={`cover_${model}`} style={styles.Cells}>
                <td className="Cells" style={styles.Cells}>
                </td>
                <td className="Cells" style={styles.Cells}>
                  {model}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 2
                    ? <StarRating rating={calculateStars(parseFloat(homeCoverEV), parseFloat(awayCoverEV))} />
                    : displayMode === 0
                    ? `${homeCoverProb}%`
                    : `${homeCoverEV}%`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 2
                    ? <StarRating rating={calculateStars(parseFloat(awayCoverEV), parseFloat(homeCoverEV))} />
                    : displayMode === 0
                    ? `${awayCoverProb}%`
                    : `${awayCoverEV}%`}
                </td>
              </tr>
            )
          )}
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Market Spread Probability + vig
            </td>
            <td className="Cells" style={styles.Cells}>
              Market
            </td>
            <td className="Cells" style={styles.Cells}>
              {homeSpreadProb}%
            </td>
            <td className="Cells" style={styles.Cells}>
              {awaySpreadProb}%
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Spread Vig
            </td>
            <td className="Cells" style={styles.Cells}>
              Market
            </td>
            <td colSpan="2" className="Cells" style={styles.Cells}>
              {spreadVig}%
            </td>
          </tr>

          <tr>
            <td colSpan="4" style={{ padding: 0 }}>
            <div style={{ borderTop: '0.1px solid white', margin: '0.1px 0' }}></div>
            </td>
          </tr>
          {/* Over/Under Group */}
          <tr style={styles.Cells}>
            <td
              colSpan="4"
              className="Cells"
              style={{ ...styles.Cells, borderTop: "2px solid black" }}
            >
              Over/Under
            </td>
          </tr>
          {modelProbabilities.map(
            ({ model, overProb, underProb, overEV, underEV }) => (
              <tr key={`ou_${model}`} style={styles.Cells}>
                <td className="Cells" style={styles.Cells}>
                </td>
                <td className="Cells" style={styles.Cells}>
                  {model}
                </td>
                <td className="Cells" style={styles.Cells}>
                  Over:{" "}
                  {displayMode === 2
                    ? <StarRating rating={calculateStars(parseFloat(overEV), parseFloat(underEV))} />
                    : displayMode === 0
                    ? `${overProb}%`
                    : `${overEV}%`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  Under:{" "}
                  {displayMode === 2
                    ? <StarRating rating={calculateStars(parseFloat(underEV), parseFloat(overEV))} />
                    : displayMode === 0
                    ? `${underProb}%`
                    : `${underEV}%`}
                </td>
              </tr>
            )
          )}
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Market Over/Under Probability + vig
            </td>
            <td className="Cells" style={styles.Cells}>
              Market
            </td>
            <td className="Cells" style={styles.Cells}>
              Over: {overProb}%
            </td>
            <td className="Cells" style={styles.Cells}>
              Under: {underProb}%
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Totals Vig
            </td>
            <td className="Cells" style={styles.Cells}>
              Market
            </td>
            <td colSpan="2" className="Cells" style={styles.Cells}>
              {totalsVig}%
            </td>
          </tr>

          {/* Other Ratings Group */}
          <tr style={styles.Cells}>
            <td
              colSpan="4"
              className="Cells"
              style={{ ...styles.Cells, borderTop: "2px solid black" }}
            >
              Other Ratings
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Elo Rating
            </td>
            <td className="Cells" style={styles.Cells}>
              Elo
            </td>
            <td className="Cells" style={styles.Cells}>
              {homeEloRating}
            </td>
            <td className="Cells" style={styles.Cells}>
              {awayEloRating}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Point Differential Rating
            </td>
            <td className="Cells" style={styles.Cells}>
              -
            </td>
            <td className="Cells" style={styles.Cells}>
              {homePointDiffRating}
            </td>
            <td className="Cells" style={styles.Cells}>
              {awayPointDiffRating}
            </td>
          </tr>
        </tbody>
      </Table>
      </>
    );
  }
  
  return null;
  
}

export default Projections;