import React from 'react';
import { ReactSVG } from 'react-svg';
import StarSvg from '../svg/star.svg';
import { styles } from '../styles/styles';

const StarRating = ({ rating }) => {
  if (rating === 0) {
    return <span>---</span>;
  }

  return (
    <span className="star-container">
      {[...Array(rating)].map((_, i) => (
        <ReactSVG 
          key={i} 
          src={StarSvg} 
          wrapper="span"
          beforeInjection={(svg) => {
            svg.setAttribute('style', `width: ${styles.star.width}; height: ${styles.star.height};`);
            svg.setAttribute('fill', styles.star.color);
          }}
        />
      ))}
    </span>
  );
};

export { StarRating };