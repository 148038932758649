import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

const PasswordResetConfirm = () => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password1 !== password2) {
      setError("Passwords don't match");
      return;
    }
    try {
      const url = process.env.REACT_APP_API_URL;
      await axios.post(`${url}/auth/password/reset/confirm/`, {
        uid,
        token,
        new_password1: password1,
        new_password2: password2
      });
      navigate('/signin');
    } catch (error) {
      setError('Request failed: ' + JSON.stringify(error.response?.data));
    }
  };

  return (
    <Container>
      <h2>Set New Password</h2>
      {error && <Alert color="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="password1">New Password</Label>
          <Input
            type="password"
            name="password1"
            id="password1"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            required
            className="bg-dark text-white mb-3 py-2"
            style={{ fontSize: '16px', height: 'auto' }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password2">Confirm New Password</Label>
          <Input
            type="password"
            name="password2"
            id="password2"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            required
            className="bg-dark text-white mb-3 py-2"
            style={{ fontSize: '16px', height: 'auto' }}
          />
        </FormGroup>
        <Button type="submit" color="primary">Set New Password</Button>
      </Form>
    </Container>
  );
};

export default PasswordResetConfirm;
