import React, { useEffect, useState } from "react";
import axios from "axios";
import Winner from "./winner.js";
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";

// Needs to be refactored later on. Too many extra calls

export function HeadtoHead({ team1, team2, game }) {
  const [data, setData] = useState([]);
  const [showPercentages, setShowPercentages] = useState(true);
  const [showPointDifference, setShowPointDifference] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_URL;
        const { data: response } = await axios.get(`${url}/getFixtures/`, {
          params: {
            team1: team1,
            team2: team2,
            date: game.game_time,
          },
        });
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, [team1, team2, game]);

  const togglePercentages = () => {
    setShowPercentages(!showPercentages);
  };

  const calculateWinPercentage = (wins, draws, total) => {
    return ((wins + draws * 0.5) / total) * 100;
  };

  const togglePointDifference = () => {
    setShowPointDifference(!showPointDifference);
  };

  function isSupportedSport(fixture) {
    const supportedSports = ["Hockey", "Soccer", "RugbyLeague", "AussieRules"];
    return supportedSports.includes(game.sport);
  }

  return (
    <div>
      <div>
        <Table bordered className="main" onClick={togglePercentages}>
          <tbody>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}></td>
              <td className="Cells" style={styles.Cells}>
                Wins
              </td>
              <td className="Cells" style={styles.Cells}></td>
              <td className="Cells" style={styles.Cells}>
                Spreads
              </td>
              <td className="Cells" style={styles.Cells}></td>
            </tr>
            <tr>
              <td className="Cells" style={styles.Cells}></td>
              <td className="Cells" style={styles.Cells}>
                {game.home_team} W/L/D
              </td>
              <td className="Cells" style={styles.Cells}>
                {game.away_team} W/L/D
              </td>
              <td className="Cells" style={styles.Cells}>
                {game.home_team}
              </td>
              <td className="Cells" style={styles.Cells}>
                {game.away_team}
              </td>
            </tr>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}>
                Last 3
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${calculateWinPercentage(
                      game.head_to_head.h2h_home_win_3,
                      game.head_to_head.h2h_draws_3,
                      3
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_home_win_3} - ${
                      game.head_to_head.h2h_home_loss_3
                    }${
                      isSupportedSport(game)
                        ? ` - ${game.head_to_head.h2h_draws_3}`
                        : ""
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${calculateWinPercentage(
                      game.head_to_head.h2h_away_win_3,
                      game.head_to_head.h2h_draws_3,
                      3
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_away_win_3} - ${
                      game.head_to_head.h2h_away_loss_3
                    }${
                      isSupportedSport(game)
                        ? ` - ${game.head_to_head.h2h_draws_3}`
                        : ""
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${(
                      (game.head_to_head.h2h_home_spread_3 / 3) *
                      100
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_home_spread_3} - ${
                      3 - game.head_to_head.h2h_home_spread_3
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${(
                      (game.head_to_head.h2h_away_spread_3 / 3) *
                      100
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_away_spread_3} - ${
                      3 - game.head_to_head.h2h_away_spread_3
                    }`}
              </td>
            </tr>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}>
                Last 5
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${calculateWinPercentage(
                      game.head_to_head.h2h_home_win_5,
                      game.head_to_head.h2h_draws_5,
                      5
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_home_win_5} - ${
                      game.head_to_head.h2h_home_loss_5
                    }${
                      isSupportedSport(game)
                        ? ` - ${game.head_to_head.h2h_draws_5}`
                        : ""
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${calculateWinPercentage(
                      game.head_to_head.h2h_away_win_5,
                      game.head_to_head.h2h_draws_5,
                      5
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_away_win_5} - ${
                      game.head_to_head.h2h_away_loss_5
                    }${
                      isSupportedSport(game)
                        ? ` - ${game.head_to_head.h2h_draws_5}`
                        : ""
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${(
                      (game.head_to_head.h2h_home_spread_5 / 5) *
                      100
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_home_spread_5} - ${
                      5 - game.head_to_head.h2h_home_spread_5
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${(
                      (game.head_to_head.h2h_away_spread_5 / 5) *
                      100
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_away_spread_5} - ${
                      5 - game.head_to_head.h2h_away_spread_5
                    }`}
              </td>
            </tr>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}>
                Last 10
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${calculateWinPercentage(
                      game.head_to_head.h2h_home_win_10,
                      game.head_to_head.h2h_draws_10,
                      10
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_home_win_10} - ${
                      game.head_to_head.h2h_home_loss_10
                    }${
                      isSupportedSport(game)
                        ? ` - ${game.head_to_head.h2h_draws_10}`
                        : ""
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${calculateWinPercentage(
                      game.head_to_head.h2h_away_win_10,
                      game.head_to_head.h2h_draws_10,
                      10
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_away_win_10} - ${
                      game.head_to_head.h2h_away_loss_10
                    }${
                      isSupportedSport(game)
                        ? ` - ${game.head_to_head.h2h_draws_10}`
                        : ""
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${(
                      (game.head_to_head.h2h_home_spread_10 / 10) *
                      100
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_home_spread_10} - ${
                      10 - game.head_to_head.h2h_home_spread_10
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${(
                      (game.head_to_head.h2h_away_spread_10 / 10) *
                      100
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_away_spread_10} - ${
                      10 - game.head_to_head.h2h_away_spread_10
                    }`}
              </td>
            </tr>
          </tbody>
        </Table>

        <Table bordered className="main" onClick={togglePointDifference}>
          <tbody>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}>
                {" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                Point Totals{" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
              </td>
            </tr>
            <tr>
              <td className="Cells" style={styles.Cells}>
                {" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                {game.home_team}{" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                {game.away_team}{" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                Overs
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                Total
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                Delta{" "}
              </td>
            </tr>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}>
                {" "}
                Last 3
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(
                      game.head_to_head.h2h_home_PF_3 -
                      game.head_to_head.h2h_home_PA_3
                    ).toFixed(1)}`
                  : `${game.head_to_head.h2h_home_PF_3} - ${game.head_to_head.h2h_home_PA_3}`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(
                      game.head_to_head.h2h_away_PF_3 -
                      game.head_to_head.h2h_away_PA_3
                    ).toFixed(1)}`
                  : `${game.head_to_head.h2h_away_PF_3} - ${game.head_to_head.h2h_away_PA_3}`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(
                      (game.head_to_head.h2h_overs_hit_3 / 3) *
                      100
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_overs_hit_3} - ${
                      3 - game.head_to_head.h2h_overs_hit_3
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                {(
                  (game.head_to_head.h2h_home_PF_3 +
                    game.head_to_head.h2h_away_PF_3 +
                    game.head_to_head.h2h_home_PA_3 +
                    game.head_to_head.h2h_away_PA_3) /
                  2
                ).toFixed(1)}{" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                {(
                  (game.head_to_head.h2h_home_PF_3 +
                    game.head_to_head.h2h_away_PF_3 +
                    game.head_to_head.h2h_home_PA_3 +
                    game.head_to_head.h2h_away_PA_3) /
                    2 -
                  game.over
                ).toFixed(1)}{" "}
              </td>
            </tr>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}>
                {" "}
                Last 5
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(
                      game.head_to_head.h2h_home_PF_5 -
                      game.head_to_head.h2h_home_PA_5
                    ).toFixed(1)}`
                  : `${game.head_to_head.h2h_home_PF_5} - ${game.head_to_head.h2h_home_PA_5}`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(
                      game.head_to_head.h2h_away_PF_5 -
                      game.head_to_head.h2h_away_PA_5
                    ).toFixed(1)}`
                  : `${game.head_to_head.h2h_away_PF_5} - ${game.head_to_head.h2h_away_PA_5}`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(
                      (game.head_to_head.h2h_overs_hit_5 / 5) *
                      100
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_overs_hit_5} - ${
                      5 - game.head_to_head.h2h_overs_hit_5
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                {(
                  (game.head_to_head.h2h_home_PF_5 +
                    game.head_to_head.h2h_away_PF_5 +
                    game.head_to_head.h2h_home_PA_5 +
                    game.head_to_head.h2h_away_PA_5) /
                  2
                ).toFixed(1)}{" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                {(
                  (game.head_to_head.h2h_home_PF_5 +
                    game.head_to_head.h2h_away_PF_5 +
                    game.head_to_head.h2h_home_PA_5 +
                    game.head_to_head.h2h_away_PA_5) /
                    2 -
                  game.over
                ).toFixed(1)}{" "}
              </td>
            </tr>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}>
                {" "}
                Last 10
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(
                      game.head_to_head.h2h_home_PF_10 -
                      game.head_to_head.h2h_home_PA_10
                    ).toFixed(1)}`
                  : `${game.head_to_head.h2h_home_PF_10} - ${game.head_to_head.h2h_home_PA_10}`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(
                      game.head_to_head.h2h_away_PF_10 -
                      game.head_to_head.h2h_away_PA_10
                    ).toFixed(1)}`
                  : `${game.head_to_head.h2h_away_PF_10} - ${game.head_to_head.h2h_away_PA_10}`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(
                      (game.head_to_head.h2h_overs_hit_10 / 10) *
                      100
                    ).toFixed(1)}%`
                  : `${game.head_to_head.h2h_overs_hit_10} - ${
                      10 - game.head_to_head.h2h_overs_hit_10
                    }`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                {(
                  (game.head_to_head.h2h_home_PF_10 +
                    game.head_to_head.h2h_away_PF_10 +
                    game.head_to_head.h2h_home_PA_10 +
                    game.head_to_head.h2h_away_PA_10) /
                  2
                ).toFixed(1)}{" "}
              </td>
              <td className="Cells" style={styles.Cells}>
                {" "}
                {(
                  (game.head_to_head.h2h_home_PF_10 +
                    game.head_to_head.h2h_away_PF_10 +
                    game.head_to_head.h2h_home_PA_10 +
                    game.head_to_head.h2h_away_PA_10) /
                    2 -
                  game.over
                ).toFixed(1)}{" "}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <h5>
        {team1} vs {team2}
      </h5>
      {data.map((fixture) => (
        <div>
          <Winner fixture={fixture} team1={team1} team2={team2} />
        </div>
      ))}
    </div>
  );
}

export default HeadtoHead;
