import React, { useEffect, useState} from 'react';
import axios from 'axios';
import Winner from './winner.js';



export function Recents({name, date}) {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () =>{
      try {
        const url = process.env.REACT_APP_API_URL
        const { data: response } = await axios.get(`${url}/getFixtures/`, {
          params : {
              team : name,
              date: date
          }
        })
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
    }

    fetchData();
  }, [name,date]);

  return (
    <div>
        <h5>{name}</h5>
        {data.map(fixture =>
           (<div> 
              <Winner 
                fixture = {fixture}  
                name = {name}/>
            </div>)
           )}
      </div>
  );
}


function facts(fixture) {
  return (
    <div>
      <p></p>
    </div>
  )
}

export default Recents