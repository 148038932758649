import React, { useState } from 'react';
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";
import { useAppState } from "../../AppStateContext.js";

const Totals = ({ fixture }) => {
  const [showPointDifference, setShowPointDifference] = useState(true);
  const { isTutorialMode } = useAppState();

  const togglePointDifference = () => {
    setShowPointDifference(!showPointDifference);
  };

  if (!fixture.recent) return null;

  return (
    <>
      {isTutorialMode && (
        <ul style={{ marginBottom: '20px', textAlign: 'left', listStyleType: 'none', paddingLeft: '0' }}>
          <li>• This table shows the total points (Points For + Points Against) for each team</li>
          <li>• Columns explanation:
            <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
              <li>◦ Home/Away - Total points for each team (PF + PA)</li>
              <li>◦ Average - Combined average of both teams' totals</li>
              <li>◦ Delta - Difference between the average and the over/under set by the market</li>
            </ul>
          </li>
          <li>• A positive Delta suggests the total might go over, while a negative Delta suggests it might go under</li>
        </ul>
      )}
      <Table bordered className="main" onClick={togglePointDifference}>
        <tbody>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>Point Totals</td>
            <td className="Cells" style={styles.Cells}> </td>
            <td className="Cells" style={styles.Cells}> </td>
            <td className="Cells" style={styles.Cells}> </td>
            <td className="Cells" style={styles.Cells}> </td>
          </tr>
          <tr>
            <td className="Cells" style={styles.Cells}> </td>
            <td className="Cells" style={styles.Cells}>{fixture.home_team}</td>
            <td className="Cells" style={styles.Cells}>{fixture.away_team}</td>
            <td className="Cells" style={styles.Cells}>Average</td>
            <td className="Cells" style={styles.Cells}>Delta</td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>Last 3</td>
            <td className="Cells" style={styles.Cells}>
              {(fixture.recent.home_PF_3 + fixture.recent.home_PA_3).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {(fixture.recent.away_PF_3 + fixture.recent.away_PA_3).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {((fixture.recent.home_PF_3 + fixture.recent.away_PF_3 + fixture.recent.home_PA_3 + fixture.recent.away_PA_3) / 2).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {((fixture.recent.home_PF_3 + fixture.recent.away_PF_3 + fixture.recent.home_PA_3 + fixture.recent.away_PA_3) / 2 - fixture.over).toFixed(1)}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>Last 5</td>
            <td className="Cells" style={styles.Cells}>
              {(fixture.recent.home_PF_5 + fixture.recent.home_PA_5).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {(fixture.recent.away_PF_5 + fixture.recent.away_PA_5).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {((fixture.recent.home_PF_5 + fixture.recent.away_PF_5 + fixture.recent.home_PA_5 + fixture.recent.away_PA_5) / 2).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {((fixture.recent.home_PF_5 + fixture.recent.away_PF_5 + fixture.recent.home_PA_5 + fixture.recent.away_PA_5) / 2 - fixture.over).toFixed(1)}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>Last 10</td>
            <td className="Cells" style={styles.Cells}>
              {(fixture.recent.home_PF_10 + fixture.recent.home_PA_10).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {(fixture.recent.away_PF_10 + fixture.recent.away_PA_10).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {((fixture.recent.home_PF_10 + fixture.recent.away_PF_10 + fixture.recent.home_PA_10 + fixture.recent.away_PA_10) / 2).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {((fixture.recent.home_PF_10 + fixture.recent.away_PF_10 + fixture.recent.home_PA_10 + fixture.recent.away_PA_10) / 2 - fixture.over).toFixed(1)}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>Season</td>
            <td className="Cells" style={styles.Cells}>
              {(fixture.stats.home_PF + fixture.stats.home_PA).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {(fixture.stats.away_PF + fixture.stats.away_PA).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {((fixture.stats.home_PF + fixture.stats.away_PF + fixture.stats.home_PA + fixture.stats.away_PA) / 2).toFixed(1)}
            </td>
            <td className="Cells" style={styles.Cells}>
              {((fixture.stats.home_PF + fixture.stats.away_PF + fixture.stats.home_PA + fixture.stats.away_PA) / 2 - fixture.over).toFixed(1)}
            </td>
          </tr>
          {shouldShowLastSeason(fixture) && (
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}>Last Season</td>
              <td className="Cells" style={styles.Cells}>
                {(fixture.stats.home_PF_last + fixture.stats.home_PA_last).toFixed(1)}
              </td>
              <td className="Cells" style={styles.Cells}>
                {(fixture.stats.away_PF_last + fixture.stats.away_PA_last).toFixed(1)}
              </td>
              <td className="Cells" style={styles.Cells}>
                {((fixture.stats.home_PF_last + fixture.stats.away_PF_last + fixture.stats.home_PA_last + fixture.stats.away_PA_last) / 2).toFixed(1)}
              </td>
              <td className="Cells" style={styles.Cells}>
                {((fixture.stats.home_PF_last + fixture.stats.away_PF_last + fixture.stats.home_PA_last + fixture.stats.away_PA_last) / 2 - fixture.over).toFixed(1)}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

// This function should be moved to a utility file
function shouldShowLastSeason(fixture) {
  if (fixture.league === "NFL") {
    return true;
  }
  return fixture.ratings.progress < 0.15;
}

export default Totals;