import React from 'react';

const Score = ({ fixture }) => {
  if (fixture.home_points !== null && fixture.home_points !== undefined) {
    return (
      <div>
        {fixture.home_team} - {fixture.home_points} <br /> {fixture.away_team} -{' '}
        {fixture.away_points} <br />
        total - {fixture.home_points + fixture.away_points}
        <div>
          <div>
            {fixture.home_team} - {fixture.ratings.home_pre_elo.toFixed(0)} ----{' '}
            {fixture.ratings.home_elo.toFixed(0)}
          </div>
          <div>
            {fixture.away_team} - {fixture.ratings.away_pre_elo.toFixed(0)} ----{' '}
            {fixture.ratings.away_elo.toFixed(0)}
          </div>
          <div>
            Margin of Victory: - {fixture.ratings.mov_mult.toFixed(2)}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Score;