import React, { useState } from 'react';
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";

const PointDiff = ({ fixture }) => {
  const [showPointDifference, setShowPointDifference] = useState(true);

  const togglePointDifference = () => {
    setShowPointDifference(!showPointDifference);
  };

  if (!fixture.recent) return null;

  return (
    <Table bordered className="main" onClick={togglePointDifference}>
      <tbody>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Point Diff</td>
          <td className="Cells" style={styles.Cells}></td>
          <td className="Cells" style={styles.Cells}></td>
        </tr>
        <tr>
          <td className="Cells" style={styles.Cells}></td>
          <td className="Cells" style={styles.Cells}>{fixture.home_team}</td>
          <td className="Cells" style={styles.Cells}>{fixture.away_team}</td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Last 3</td>
          <td className="Cells" style={styles.Cells}>
            {showPointDifference
              ? `${(fixture.recent.home_PF_3 - fixture.recent.home_PA_3).toFixed(1)}`
              : `${fixture.recent.home_PF_3} - ${fixture.recent.home_PA_3}`}
          </td>
          <td className="Cells" style={styles.Cells}>
            {showPointDifference
              ? `${(fixture.recent.away_PF_3 - fixture.recent.away_PA_3).toFixed(1)}`
              : `${fixture.recent.away_PF_3} - ${fixture.recent.away_PA_3}`}
          </td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Last 5</td>
          <td className="Cells" style={styles.Cells}>
            {showPointDifference
              ? `${(fixture.recent.home_PF_5 - fixture.recent.home_PA_5).toFixed(1)}`
              : `${fixture.recent.home_PF_5} - ${fixture.recent.home_PA_5}`}
          </td>
          <td className="Cells" style={styles.Cells}>
            {showPointDifference
              ? `${(fixture.recent.away_PF_5 - fixture.recent.away_PA_5).toFixed(1)}`
              : `${fixture.recent.away_PF_5} - ${fixture.recent.away_PA_5}`}
          </td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Last 10</td>
          <td className="Cells" style={styles.Cells}>
            {showPointDifference
              ? `${(fixture.recent.home_PF_10 - fixture.recent.home_PA_10).toFixed(1)}`
              : `${fixture.recent.home_PF_10} - ${fixture.recent.home_PA_10}`}
          </td>
          <td className="Cells" style={styles.Cells}>
            {showPointDifference
              ? `${(fixture.recent.away_PF_10 - fixture.recent.away_PA_10).toFixed(1)}`
              : `${fixture.recent.away_PF_10} - ${fixture.recent.away_PA_10}`}
          </td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Season</td>
          <td className="Cells" style={styles.Cells}>
            {showPointDifference
              ? `${(fixture.stats.home_PF - fixture.stats.home_PA).toFixed(1)}`
              : `${fixture.stats.home_PF} - ${fixture.stats.home_PA}`}
          </td>
          <td className="Cells" style={styles.Cells}>
            {showPointDifference
              ? `${(fixture.stats.away_PF - fixture.stats.away_PA).toFixed(1)}`
              : `${fixture.stats.away_PF} - ${fixture.stats.away_PA}`}
          </td>
        </tr>
        {shouldShowLastSeason(fixture) && (
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>Last Season</td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.stats.home_PF_last - fixture.stats.home_PA_last).toFixed(1)}`
                : `${fixture.stats.home_PF_last} - ${fixture.stats.home_PA_last}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.stats.away_PF_last - fixture.stats.away_PA_last).toFixed(1)}`
                : `${fixture.stats.away_PF_last} - ${fixture.stats.away_PA_last}`}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

// This function should be moved to a utility file
function shouldShowLastSeason(fixture) {
  if (fixture.league === "NFL") {
    return fixture.ratings.progress < 0.2;
  }
  return fixture.ratings.progress < 0.15;
}

export default PointDiff;