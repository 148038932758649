// const width = window.innerWidth <= 768 ? "55rem" : "80rem";
const width =  "clamp(55rem, 70vw, 100rem)";
const odds =
{
  textAlign: "center",
  backgroundColor: "black",
  color: "white",
};

const cells =
{
  textAlign: "center",
  backgroundColor: "black",
  color: "white",
  border: 0,
};

const suggested =
{
  textAlign: "center",
  backgroundColor: "green"
};

const main =
{
  backgroundColor: "black",
  color: "white",
  width: width
}

const cardbutton =
{
  // textAlign: "center", 
  backgroundColor: "black",
  color: "white",
  width: `calc(${width} - 3rem)`,
  borderColor: "white",
  borderWidth: 1,
  borderStyle: "solid",
  padding: 20
}


const comment =
{
  // textAlign: "center", 
  backgroundColor: "black",
  color: "white",
  width: `calc(${width} - 3rem)`,
  borderColor: "white",
  borderWidth: 1,
  borderStyle: "solid",
  padding: 20
}

const star = {
  width: 'clamp(0.5rem, 5vw, 1.25rem)',
  height: 'auto',
  color: 'white'  // This will set the fill color of the star
}

const commentCard = {
  marginBottom: '10px',
  backgroundColor: 'black',
  color: 'white',
  borderColor: 'white',
  borderWidth: 1,
  borderStyle: 'solid',
  padding: 20,
};

const commentHeader = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
};

const username = {
  fontWeight: 'bold',
  marginRight: '10px',
  color: '#4e79a7', // A blue shade, you can adjust this color
};

const score = {
  color: '#888',
  marginRight: '10px',
};

const timestamp = {
  color: '#888',
  fontSize: '0.8em',
};

export const styles = {
  Odds: odds,
  Suggested: suggested,
  main: main,
  Cells: cells,
  cardbutton: cardbutton,
  comment: comment,
  star: star,
  commentCard: commentCard,
  commentHeader: commentHeader,
  username: username,
  score: score,
  timestamp: timestamp,
}