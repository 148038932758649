import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = process.env.REACT_APP_API_URL;
      await axios.post(`${url}/auth/password/reset/`, { email });
      setSuccess('Password reset email has been sent. Please check your inbox.');
      setError('');
    } catch (error) {
      setError('Request failed: ' + JSON.stringify(error.response?.data));
      setSuccess('');
    }
  };

  return (
    <Container>
      <h2>Reset Password</h2>
      {error && <Alert color="danger">{error}</Alert>}
      {success && <Alert color="success">{success}</Alert>}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="bg-dark text-white mb-3 py-2"
            style={{ fontSize: '16px', height: 'auto' }}
          />
        </FormGroup>
        <Button type="submit" color="primary">Reset Password</Button>
      </Form>
    </Container>
  );
};

export default PasswordResetRequest;
