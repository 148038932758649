import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";
import { StarRating } from "../../components/starrating.js";

function shouldShowLastSeason(fixture) {
  if (fixture.league === "NFL" || fixture.league === "NHL") {
    return true;
  }
  return fixture.ratings.progress < 0.15;
}

function calculateWinPercentage(wins, draws, total) {
  return ((wins + draws * 0.5) / total) * 100;
}

const calculateStars = (ev1, ev2) => {
  if (isNaN(ev1) || isNaN(ev2)) return 0;
  const difference = ev1 - ev2;
  if (difference <= 0) return 0;
  if (difference > 0 && difference <= 10) return 1;
  if (difference > 10 && difference <= 30) return 2;
  return 3;
};

function Spreads({ fixture }) {
  const [displayMode, setDisplayMode] = useState(2); // 0: Win-Loss, 1: Percentages, 2: Stars (default)

  const toggleDisplayMode = () => {
    setDisplayMode((prevMode) => (prevMode + 1) % 3);
  };

  if (fixture.recent) {
    return (
      <Table bordered className="main" onClick={toggleDisplayMode}>
        <tbody>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Spreads
            </td>
            <td className="Cells" style={styles.Cells}></td>
            <td className="Cells" style={styles.Cells}></td>
          </tr>
          <tr>
            <td className="Cells" style={styles.Cells}></td>
            <td className="Cells" style={styles.Cells}>
              {fixture.home_team}
            </td>
            <td className="Cells" style={styles.Cells}>
              {fixture.away_team}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Last 3
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.recent.home_spread_3} - ${3 - fixture.recent.home_spread_3}`
                : displayMode === 1
                ? `${((fixture.recent.home_spread_3 / 3) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.home_spread_3 / 3) * 100,
                    (fixture.recent.away_spread_3 / 3) * 100
                  )} />
              }
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.recent.away_spread_3} - ${3 - fixture.recent.away_spread_3}`
                : displayMode === 1
                ? `${((fixture.recent.away_spread_3 / 3) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.away_spread_3 / 3) * 100,
                    (fixture.recent.home_spread_3 / 3) * 100
                  )} />
              }
            </td>
          </tr>

          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Last 5
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.recent.home_spread_5} - ${5 - fixture.recent.home_spread_5}`
                : displayMode === 1
                ? `${((fixture.recent.home_spread_5 / 5) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.home_spread_5 / 5) * 100,
                    (fixture.recent.away_spread_5 / 5) * 100
                  )} />
              }
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.recent.away_spread_5} - ${5 - fixture.recent.away_spread_5}`
                : displayMode === 1
                ? `${((fixture.recent.away_spread_5 / 5) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.away_spread_5 / 5) * 100,
                    (fixture.recent.home_spread_5 / 5) * 100
                  )} />
              }
            </td>
          </tr>

          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Last 10
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.recent.home_spread_10} - ${10 - fixture.recent.home_spread_10}`
                : displayMode === 1
                ? `${((fixture.recent.home_spread_10 / 10) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.home_spread_10 / 10) * 100,
                    (fixture.recent.away_spread_10 / 10) * 100
                  )} />
              }
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.recent.away_spread_10} - ${10 - fixture.recent.away_spread_10}`
                : displayMode === 1
                ? `${((fixture.recent.away_spread_10 / 10) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.away_spread_10 / 10) * 100,
                    (fixture.recent.home_spread_10 / 10) * 100
                  )} />
              }
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Season
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.stats.home_spread} - ${fixture.stats.count_home - fixture.stats.home_spread}`
                : displayMode === 1
                ? `${((fixture.stats.home_spread / fixture.stats.count_home) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.stats.home_spread / fixture.stats.count_home) * 100,
                    (fixture.stats.away_spread / fixture.stats.count_away) * 100
                  )} />
              }
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.stats.away_spread} - ${fixture.stats.count_away - fixture.stats.away_spread}`
                : displayMode === 1
                ? `${((fixture.stats.away_spread / fixture.stats.count_away) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.stats.away_spread / fixture.stats.count_away) * 100,
                    (fixture.stats.home_spread / fixture.stats.count_home) * 100
                  )} />
              }
            </td>
          </tr>
          {shouldShowLastSeason(fixture) && (
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.Cells}>
                Last Season
              </td>
              <td className="Cells" style={styles.Cells}>
                {displayMode === 0
                  ? `${fixture.stats.home_spread_last} - ${fixture.stats.count_home_last - fixture.stats.home_spread_last}`
                  : displayMode === 1
                  ? `${((fixture.stats.home_spread_last / fixture.stats.count_home_last) * 100).toFixed(1)}%`
                  : <StarRating rating={calculateStars(
                      (fixture.stats.home_spread_last / fixture.stats.count_home_last) * 100,
                      (fixture.stats.away_spread_last / fixture.stats.count_away_last) * 100
                    )} />
                }
              </td>
              <td className="Cells" style={styles.Cells}>
                {displayMode === 0
                  ? `${fixture.stats.away_spread_last} - ${fixture.stats.count_away_last - fixture.stats.away_spread_last}`
                  : displayMode === 1
                  ? `${((fixture.stats.away_spread_last / fixture.stats.count_away_last) * 100).toFixed(1)}%`
                  : <StarRating rating={calculateStars(
                      (fixture.stats.away_spread_last / fixture.stats.count_away_last) * 100,
                      (fixture.stats.home_spread_last / fixture.stats.count_home_last) * 100
                    )} />
                }
              </td>
            </tr>
          )}
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Home
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.stats.home_home_spreads_covered} - ${fixture.stats.home_home_spreads_not_covered}`
                : displayMode === 1
                ? `${calculateWinPercentage(
                    fixture.stats.home_home_spreads_covered,
                    0,
                    fixture.stats.home_home_spreads_covered + fixture.stats.home_home_spreads_not_covered
                  ).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    calculateWinPercentage(fixture.stats.home_home_spreads_covered, 0, fixture.stats.home_home_spreads_covered + fixture.stats.home_home_spreads_not_covered),
                    calculateWinPercentage(fixture.stats.away_away_spreads_covered, 0, fixture.stats.away_away_spreads_covered + fixture.stats.away_away_spreads_not_covered)
                  )} />
              }
            </td>
            <td className="Cells" style={styles.Cells}></td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.Cells}>
              Away
            </td>
            <td className="Cells" style={styles.Cells}></td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.stats.away_away_spreads_covered} - ${fixture.stats.away_away_spreads_not_covered}`
                : displayMode === 1
                ? `${calculateWinPercentage(
                    fixture.stats.away_away_spreads_covered,
                    0,
                    fixture.stats.away_away_spreads_covered + fixture.stats.away_away_spreads_not_covered
                  ).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    calculateWinPercentage(fixture.stats.away_away_spreads_covered, 0, fixture.stats.away_away_spreads_covered + fixture.stats.away_away_spreads_not_covered),
                    calculateWinPercentage(fixture.stats.home_home_spreads_covered, 0, fixture.stats.home_home_spreads_covered + fixture.stats.home_home_spreads_not_covered)
                  )} />
              }
            </td>
          </tr>
          {shouldShowLastSeason(fixture) && (
            <>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.Cells}>
                  Home Last Season
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0
                    ? `${fixture.stats.home_home_spreads_covered_last} - ${fixture.stats.home_home_spreads_not_covered_last}`
                    : displayMode === 1
                    ? `${calculateWinPercentage(
                        fixture.stats.home_home_spreads_covered_last,
                        0,
                        fixture.stats.home_home_spreads_covered_last + fixture.stats.home_home_spreads_not_covered_last
                      ).toFixed(1)}%`
                    : <StarRating rating={calculateStars(
                        calculateWinPercentage(fixture.stats.home_home_spreads_covered_last, 0, fixture.stats.home_home_spreads_covered_last + fixture.stats.home_home_spreads_not_covered_last),
                        calculateWinPercentage(fixture.stats.away_home_spreads_covered_last, 0, fixture.stats.away_home_spreads_covered_last + fixture.stats.away_home_spreads_not_covered_last)
                      )} />
                  }
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0
                    ? `${fixture.stats.away_home_spreads_covered_last} - ${fixture.stats.away_home_spreads_not_covered_last}`
                    : displayMode === 1
                    ? `${calculateWinPercentage(
                        fixture.stats.away_home_spreads_covered_last,
                        0,
                        fixture.stats.away_home_spreads_covered_last + fixture.stats.away_home_spreads_not_covered_last
                      ).toFixed(1)}%`
                    : <StarRating rating={calculateStars(
                        calculateWinPercentage(fixture.stats.away_home_spreads_covered_last, 0, fixture.stats.away_home_spreads_covered_last + fixture.stats.away_home_spreads_not_covered_last),
                        calculateWinPercentage(fixture.stats.home_home_spreads_covered_last, 0, fixture.stats.home_home_spreads_covered_last + fixture.stats.home_home_spreads_not_covered_last)
                      )} />
                  }
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.Cells}>
                  Away Last Season
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0
                    ? `${fixture.stats.home_away_spreads_covered_last} - ${fixture.stats.home_away_spreads_not_covered_last}`
                    : displayMode === 1
                    ? `${calculateWinPercentage(
                        fixture.stats.home_away_spreads_covered_last,
                        0,
                        fixture.stats.home_away_spreads_covered_last + fixture.stats.home_away_spreads_not_covered_last
                      ).toFixed(1)}%`
                    : <StarRating rating={calculateStars(
                        calculateWinPercentage(fixture.stats.home_away_spreads_covered_last, 0, fixture.stats.home_away_spreads_covered_last + fixture.stats.home_away_spreads_not_covered_last),
                        calculateWinPercentage(fixture.stats.away_away_spreads_covered_last, 0, fixture.stats.away_away_spreads_covered_last + fixture.stats.away_away_spreads_not_covered_last)
                      )} />
                  }
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0
                    ? `${fixture.stats.away_away_spreads_covered_last} - ${fixture.stats.away_away_spreads_not_covered_last}`
                    : displayMode === 1
                    ? `${calculateWinPercentage(
                        fixture.stats.away_away_spreads_covered_last,
                        0,
                        fixture.stats.away_away_spreads_covered_last + fixture.stats.away_away_spreads_not_covered_last
                      ).toFixed(1)}%`
                    : <StarRating rating={calculateStars(
                        calculateWinPercentage(fixture.stats.away_away_spreads_covered_last, 0, fixture.stats.away_away_spreads_covered_last + fixture.stats.away_away_spreads_not_covered_last),
                        calculateWinPercentage(fixture.stats.home_away_spreads_covered_last, 0, fixture.stats.home_away_spreads_covered_last + fixture.stats.home_away_spreads_not_covered_last)
                      )} />
                  }
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    );
  }
  return null;
}

export default Spreads;