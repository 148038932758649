import React, { useState } from "react";
import Recents from "./Cards/recents.js";
import HeadtoHead from "./Cards/headtohead.js";
import Score from "./Cards/score.js";
import Versus from "./Cards/versus.js";
import Totals from "./Cards/totals.js";
import PointDiff from "./Cards/pointdiff.js";
import Overs from "./Cards/overs.js";
import Wins from "./Cards/wins.js";
import Spreads from "./Cards/spreads.js";
import Projections from "./Cards/projections.js";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Comments from "./Cards/comments.js";
import { convertUTCDateToLocalDateTime } from "../utils/localtime.js";
import { styles } from "../styles/styles.js";
import { useAppState } from "../AppStateContext.js";

const Games = ({ fixture, isAuthenticated }) => {
  const [isDataVisible, setDataVisible] = useState(false);
  const [isRecentsVisible, setRecentsVisible] = useState(false);
  const [isHeadtoHeadVisible, setHeadtoHeadVisible] = useState(false);
  const [isPowerRatingsVisible, setPowerRatingsVisible] = useState(false);
  const { isTutorialMode } = useAppState();

  const toggleDataVisibility = () => {
    setDataVisible(!isDataVisible);
  };

  const toggleRecentsVisibility = () => {
    setRecentsVisible(!isRecentsVisible);
  };

  const toggleHeadtoHeadVisibility = () => {
    setHeadtoHeadVisible(!isHeadtoHeadVisible);
  };

  const togglePowerRatingsVisibility = () => {
    setPowerRatingsVisible(!isPowerRatingsVisible);
  };

  return (
    <div>
      <div>
        <Card className="main" style={styles.main}>
          <Card.Body>
            <Card.Title>
              <b>
                {" "}
                <Versus fixture={fixture} /> at{" "}
                {convertUTCDateToLocalDateTime(fixture.game_time)}
              </b>
            </Card.Title>
            <Table className="main" bordered onClick={toggleDataVisibility}>
              <tbody>
                <tr>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    Home{" "}
                  </td>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    {fixture.home_team}{" "}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.home_spread === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.home_spread} ({fixture.home_spread_price})
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.home_ml === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.home_moneyline}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.over === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.over} ({fixture.over_price}){" "}
                  </td>
                </tr>
                <tr>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    Away{" "}
                  </td>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    {fixture.away_team}{" "}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.away_spread === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.away_spread} ({fixture.away_spread_price}){" "}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.away_ml === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.away_moneyline}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.under === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.under} ({fixture.under_price})
                  </td>
                </tr>
              </tbody>
            </Table>
            {isDataVisible && (
              <Card.Text>
                {isAuthenticated && fixture.insights && (
                  <Projections fixture={fixture} />
                )}
                <Spacer size={32} />
                <Wins fixture={fixture} />
                <Spacer size={32} />
                <Spreads fixture={fixture} />
                <Spacer size={32} />
                <PointDiff fixture={fixture} />
                <Spacer size={32} />
                <Totals fixture={fixture} />
                <Spacer size={32} />
                <Overs fixture={fixture} />
                <Card
                  className="cardbutton"
                  style={styles.cardbutton}
                  onClick={toggleRecentsVisibility}
                >
                  <Card.Text>Recent Games</Card.Text>
                </Card>
                <div style={{ marginTop: "1rem" }}>
                  {isRecentsVisible && (
                    <>
                      <Recents
                        name={fixture.home_team}
                        date={fixture.game_time}
                      />
                      <Recents
                        name={fixture.away_team}
                        date={fixture.game_time}
                      />
                    </>
                  )}
                </div>
                <Card
                  className="cardbutton"
                  style={styles.cardbutton}
                  onClick={toggleHeadtoHeadVisibility}
                >
                  <Card.Text>Head to Head</Card.Text>
                </Card>
                <div style={{ marginTop: "1rem" }}>
                  {isHeadtoHeadVisible && (
                    <>
                      <HeadtoHead
                        team1={fixture.home_team}
                        team2={fixture.away_team}
                        game={fixture}
                      />
                    </>
                  )}
                </div>
                <Card
                  className="cardbutton"
                  style={styles.cardbutton}
                  onClick={togglePowerRatingsVisibility}
                >
                  <Card.Text>Projections</Card.Text>
                </Card>
                {isPowerRatingsVisible && isAuthenticated && fixture.insights && (
                  <>
                    <Projections fixture={fixture} />
                  </>
                )}
                <div style={{ marginTop: "1rem" }}> </div>
                <Comments fixture={fixture} isAuthenticated={isAuthenticated} />
                <Spacer size={32} />
                <Score fixture={fixture} />
              </Card.Text>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

const Spacer = ({ size }) => <div style={{ height: size }} />;

export default Games;
