export function convertUTCDateToLocalDateTime(date) {
  var dateLocal = new Date(date);
  var options = {
    weekday:'short',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return dateLocal.toLocaleString('en-US', options);
  }

  export function convertUTCDateToLocalDate(date) {
    var dateLocal = new Date(date);
    var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
    newDate.setHours(0);
    
    var options = {
      weekday: 'short',
      month: 'numeric',
      day: '2-digit',
      year: '2-digit'
    };
    
    return newDate.toLocaleDateString('en-US', options);
  }