import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../AppStateContext';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  Alert
} from 'reactstrap';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const { login } = useAppState();
  const navigate = useNavigate();

  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password1 !== password2) {
      setError("Passwords don't match");
      return;
    }
    try {
      const url = process.env.REACT_APP_API_URL;
      await axios.post(`${url}/auth/registration/`, {
        username,
        email,
        password1,
        password2
      });
      const loginResponse = await axios.post(`${url}/auth/login/`, {
        username,
        password: password1
      });
      login(loginResponse.data.token);
      navigate('/');
    } catch (error) {
      console.error('Error response:', error.response);
      console.error('Error data:', error.response?.data);
      setError('Request failed: ' + JSON.stringify(error.response?.data));
    }
  };

  return (
    <Container fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh', backgroundColor: 'black', color: 'white' }}>
      <Row className="w-100 justify-content-center">
        <Col xs="11" sm="10" md="8" lg="6" xl="4">
          <Card className="bg-dark text-white">
            <CardHeader className="bg-black text-white text-center py-3">
              <h2 className="h3 mb-0" style={{ fontSize: '1.5rem' }}>Register</h2>
            </CardHeader>
            <CardBody>
              {error && <Alert color="danger" className="text-center">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="username" className="mb-2" style={{ fontSize: '1.1rem' }}>Username</Label>
                  <Input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="bg-dark text-white mb-3 py-2"
                    style={{ fontSize: '16px', height: 'auto' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email" className="mb-2" style={{ fontSize: '1.1rem' }}>Email</Label>
                  <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="bg-dark text-white mb-3 py-2"
                    style={{ fontSize: '16px', height: 'auto' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password1" className="mb-2" style={{ fontSize: '1.1rem' }}>Password</Label>
                  <Input
                    type="password"
                    id="password1"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                    required
                    className="bg-dark text-white mb-3 py-2"
                    style={{ fontSize: '16px', height: 'auto' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password2" className="mb-2" style={{ fontSize: '1.1rem' }}>Confirm Password</Label>
                  <Input
                    type="password"
                    id="password2"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    required
                    className="bg-dark text-white mb-3 py-2"
                    style={{ fontSize: '16px', height: 'auto' }}
                  />
                </FormGroup>
                <Button color="primary" block className="mt-4 py-2" style={{ fontSize: '1.1rem' }}>Register</Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;