import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Games from '../components/games.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { useAppState } from '../AppStateContext';

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const ListOfGames = ({ league, selectedDate }) => {
  const [loading, setLoading] = useState(true);
  const [groupedData, setGroupedData] = useState({});
  const { isAuthenticated } = useAppState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const url = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('authToken');
        const headers = token ? { Authorization: `Token ${token}` } : {};
        
        const { data: response } = await axios.get(`${url}/getFixtures/`, {
          params: { league: league, date: selectedDate },
          headers: headers
        });

        // Group fixtures by exact game time
        const grouped = response.reduce((acc, fixture) => {
          const gameTime = formatTime(fixture.game_time);
          
          if (!acc[gameTime]) {
            acc[gameTime] = [];
          }
          acc[gameTime].push(fixture);
          return acc;
        }, {});

        // Sort fixtures within each group by absolute value of home_spread
        Object.keys(grouped).forEach(key => {
          grouped[key].sort((a, b) => {
            const spreadA = Math.abs(parseFloat(a.home_spread));
            const spreadB = Math.abs(parseFloat(b.home_spread));
            return spreadA - spreadB;
          });
        });

        // Sort the game times
        const sortedGroupedData = Object.keys(grouped)
          .sort((a, b) => new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b))
          .reduce((acc, key) => {
            acc[key] = grouped[key];
            return acc;
          }, {});

        setGroupedData(sortedGroupedData);
        console.log("Got response at " + new Date().toLocaleTimeString());
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [league, selectedDate]);

  return (
    <Container>
      <Col>
        {loading ? (
          <p>Loading...</p>
        ) : (
          Object.entries(groupedData).map(([gameTime, fixtures]) => (
            <React.Fragment key={gameTime}>
              <h3 style={{ color: 'white', marginTop: '20px' }}>{gameTime}</h3>
              <hr style={{ borderTop: '2px solid white', margin: '10px 0' }} />
              {fixtures.map((fixture) => (
                <Games 
                  key={fixture.id}
                  fixture={fixture} 
                  isAuthenticated={isAuthenticated} 
                />
              ))}
            </React.Fragment>
          ))
        )}
      </Col>
    </Container>
  );
};

export default ListOfGames;